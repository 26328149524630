<template>
    <div style="text-align:center">
        <div class="not-font2">oops</div>
        <div class="not-font1">해당 서비스는 PC버전에서<br/>
원활하게 이용 가능합니다.</div>
        <img  style="width:80%;margin-bottom:40px" src="@/assets/images/pages/oops.png"/>
        <div class="not-btn1" @click="$router.push('/').catch(() => {})">메인으로 돌아가기</div>
    </div>
</template>

<script>
export default {};
</script>

<style>
.not-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.67px;
  text-align: center;
  color: #444444;
  margin-top: 8px;
  margin-bottom: 18px;
}

.not-font2 {
  margin-top: 145px;
  font-family: "Muli";
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.11px;
  color: #0264fb;
}

.not-btn1 {
  width: 90%;
  cursor: pointer;
  padding-top: 10px;
  height: 40px;
  border-radius: 5px;
  background-color: #0264fb;
  margin: 0 auto;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #ffffff;
}
</style>
